//recaptcha google v3
$('.houdiniForm').submit(function(event) {
   // console.log(form.find('button').text());
    event.preventDefault();
    var form = $(this);

    var isFormValid =form.valid();

    if (isFormValid) {
        //codi analytics
        //console.log('entra');

        dataLayer.push({
            'event': 'eventoGA4',
            'event_name': 'form_submit',
            'form_id': form.attr('id'), //Atributo id de HTML del elemento DOM
            'form_name': form.attr('name'), //Atributo name de HTML del
            'form_destination': form.attr('action'), //URL a la que se envía el formulario.
            'form_submit_text': form.find('button').text() //Texto del botón para enviar (si se incluye).
        });

        grecaptcha.ready(function() {

            grecaptcha.execute(RECAPTCHA_SITE_KEY, {action: 'formsubmit'}).then(function(token) {
                form.prepend('<input type="hidden" name="token" value="' + token + '">');
                form.prepend('<input type="hidden" name="action" value="formsubmit">');
                form.unbind('submit').submit();
            });

        });
    }
});
