jQuery.validator.addMethod('dni', function(value, element) {
    'use strict';
    var cadenadni = 'TRWAGMYFPDXBNJZSQVHLCKET';
    var word = value.substr((value.length - 1), 1);
    var letra = '';
    var numero = '';
    if (/^([0-9]{8})*[a-zA-Z]+$/.test(value)) {
        numero = value.substr(0, value.length - 1) % 23;
        letra = cadenadni.substring(numero, numero + 1);
        if (letra == word) {
            return true;
        }
        return false;
    } else if (/^[XYZ]{1}/.test(value)) {
        var reemplazar = new Array('X', 'Y', 'Z');
        var por = new Array('0', '1', '2');
        numero = value;
        for (var i = 0; i < reemplazar.length; i++) {
            numero = numero.replace(reemplazar[i].toUpperCase(), por[i]);
        }
        numero = numero.substr(0, value.length - 1) % 23;
        letra = cadenadni.substring(numero, numero + 1);
        if (letra == word) {
            return true;
        }
        return false;
    }
    return this.optional(element);
}, 'Please enter a valid DNI.');

jQuery.validator.addMethod('exactlength', function(value, element, param) {
    'use strict';
    return this.optional(element) || value.length == param;
}, $.validator.format('Please enter exactly {0} characters.'));

jQuery.validator.addMethod('duplicateControl', function(value, element, param) {
    'use strict';
    var formId = $('#formId').val();
    var data = {form: formId, item: param, value: value};
    result = $.ajax({
        type: 'POST',
        url: urlBase + '/media/js/formularis/ajax_hasDuplicateItems.php',
        data: data,
        async: false
    }).responseText;

    return this.optional(element) || result != 1;
}, 'Please enter another value, this field does not allow duplicates.');


jQuery.validator.addMethod("duplicateFields", function(value, element, params) {
    var prefix = params;
    var selector = jQuery.validator.format("[data-unique]", element.name, prefix);
    var matches = new Array();
    $(selector).each(function(index, item) {
        if (value == $(item).val()) {
            matches.push(item);
        }
    });
    return matches.length == 1;
}, 'Please enter another value, this field does not allow duplicates.');

/*
jQuery.validator.classRuleSettings.duplicateFields = {
    duplicateFields: true
};*/



jQuery.validator.addMethod('duplicat2eEmail', function(value, element, param) {
    'use strict';
    var formId = $('#formId').val();

    var $current = $(this);

    $(".classes fieldset").find("[type=email]").each(function() {

       //var professors = $(this).val();
        console.log('p'+$(this).val());

       /* if ($(this).val() == $current.val() && $(this).attr('professor-email') != $current.attr('professor-email'))
        {
            console.log('duplicate found!');
        }*/
     });

    //console.log('p'+formId);

    /*$(param).each(function() {
        if ($(this).val() == $current.val() && $(this).attr('id') != $current.attr('id'))
        {
            alert('duplicate found!');
        }

    }); */
    /*var data = {form: formId, item: param, value: value};
    result = $.ajax({
        type: 'POST',
        url: urlBase + '/media/js/formularis/ajax_validarProfessor.php',
        data: data,
        async: false
    }).responseText;

    return this.optional(element) || result != 1;*/

    return result=0;
}, 'Please enter another value, this field does not allow duplicates.');

$(document).ready(function() {
    'use strict';
    var lang = $('html').attr('lang');
    if (lang === 'ca') {
        jQuery.extend(jQuery.validator.messages, {
            dni: 'Si us plau, entreu un DNI vàlid.',
            exactlength: $.validator.format('Si us plau, entreu exactament {0} caràctes.'),
            duplicateControl: 'Si us plau, entreu un altre valor. Aquest camp no permet duplicats.'
        });
    } else if (lang === 'es') {
        jQuery.extend(jQuery.validator.messages, {
            dni: 'Por favor, escribe un DNI válido.',
            exactlength: $.validator.format('Por favor, escribe exactamente {0} caracteres.'),
            duplicateControl: 'Por favor, escribe otro valor. Este campo no admite duplicados.'
        });
    }
});
